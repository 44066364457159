import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col, Form, Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Circles } from 'react-loader-spinner'; // Ensure this is the correct loader component
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formFields } from './EditProfileConfig.js';
import './EditProfile.css';
import service from '../../../services/service.js';
import HeaderTwo from '../../HeaderTwo.js';
import { BreadcrumbBox } from '../../common/Breadcrumb.js';

const EditProfile = (props) => {

  let id = props.match.params.id;

  const { t } = useTranslation();
  const history = useHistory();

  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allNationalities, setAllNationalities] = useState([]);
  const [allQualifications, setAllQualifications] = useState([]);
  const [allDesignations, setAllDesignations] = useState([]);
  const [allServiceDistricts, setAllServiceDistricts] = useState([]);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({ show: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fieldStates, setFieldStates] = useState({
    otherCountryVisible: false //// INITIAL STATE WILL BE NOT VIDIBLE

  });


  useEffect(() => {
    fetchUserDetails();
    // getAllCountry();
    // getAllStates();
    // getAllCategories();
    // getAllNationalities();
    // getAllQualifications();
    getAllDesignations();
    getAllServiceDistricts();
  }, [id]);

  const fetchUserDetails = () => {
    service.getUserProfileById(id)
      .then((response) => {
        setFormData(response.data);
      })
      .catch((err) => {
        console.error('Error fetching profile data:', err);
      });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors(prev => ({ ...prev, [name]: error }));

    // Iterate over form fields to check dependencies and update field states
    formFields.forEach((field) => {
      // Handle enabled dependencies
      if (field.enabledDependencies && field.enabledDependencies.field === name) {
        const shouldEnable = field.enabledDependencies.condition(value);
        setFieldStates((prev) => ({
          ...prev,
          [`${field.name}Disabled`]: !shouldEnable,
        }));
      }

      // Handle visibility dependencies
      if (field.visibilityDependencies && field.visibilityDependencies.field === name) {
        const shouldShow = field.visibilityDependencies.condition(value);
        setFieldStates((prev) => ({
          ...prev,
          [`${field.name}Visible`]: shouldShow,
        }));
      }

      // Handle requireDependencies
      if (field.requireDependencies && field.requireDependencies.field === name) {
        const shouldRequire = field.requireDependencies.condition(value);

        setFieldStates((prev) => ({
          ...prev,
          [`${field.name}Required`]: shouldRequire,
        }));

        // Validate the field if it becomes required
        if (shouldRequire) {
          const validationError = validateField(
            field.name,
            formData[field.name] || ''
          );
          setErrors((prev) => ({ ...prev, [field.name]: validationError }));
        } else {
          // Clear errors if the field is no longer required
          setErrors((prev) => ({ ...prev, [field.name]: null }));
        }
      }
    });
  };

  const getAllCountry = () => {
    setPageLoading(true);
    service.getCountry()
      .then((res) => {
        setAllCountries(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Country Data", "error");
      })
  }

  const getAllStates = () => {
    setPageLoading(true);
    service.getState()
      .then((res) => {
        setAllStates(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load States Data", "error");
      })
  }

  const getAllServiceDistricts = () => {
    setPageLoading(true);
    service.RegisterServiceDistrict()
      .then((res) => {
        setAllServiceDistricts(res.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Qualification Data", "error");
      })
  }

  const getAllNationalities = () => {
    setPageLoading(true);
    service.getAllUserNationality()
      .then((res) => {
        setAllNationalities(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load States Data", "error");
      })
  }

  const getAllQualifications = () => {
    setPageLoading(true);
    service.RegisterQualification()
      .then((res) => {
        setAllQualifications(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Qualification Data", "error");
      })
  }

  const getAllDesignations = () => {
    setPageLoading(true);
    service.RegisterDesignation()
      .then((res) => {
        setAllDesignations(res.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Qualification Data", "error");
      })
  }

  const getAllCategories = () => {
    setPageLoading(true);
    service.getAllUserCategory()
      .then((res) => {
        setAllCategories(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load States Data", "error");
      })
  }



  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    const field = formFields.find((field) => field.name === name);

    if (!file) {
      setErrors((prev) => ({ ...prev, [name]: "File is required." }));
      return;
    }

    // Validate file size
    if (file.size > field.maxSize) {
      setErrors((prev) => ({
        ...prev,
        [name]: `File size exceeds the limit of ${field.maxSize / (1024)} KB.`,
      }));
      return;
    }

    // Validate file type
    if (!field.accept.includes(file.type)) {
      setErrors((prev) => ({
        ...prev,
        [name]: `Invalid file type. Allowed types: ${field.accept.join(", ")}.`,
      }));
      return;
    }

    // Clear any previous errors
    setErrors((prev) => ({ ...prev, [name]: null }));

    // Update the form data
    setFormData((prev) => ({
      ...prev,
      [name]: file,
    }));
  };

  const validateField = (name, value) => {
    const field = formFields.find((f) => f.name === name);
    if (!field) return null;

    // If the field is required, validate for non-empty value
    if ((fieldStates[`${name}Required`] || field.required)) {
      if (field.type === 'file' && (!value || value.length === 0)) {
        return `${field.label} is required`;
      }

      if (field.type === 'select' && (!value || value === '')) {
        return `${field.label} is required`;
      }

      if (typeof value === 'string' && value.trim() === '') {
        return `${field.label} is required`;
      }
    }

    // If the field is not required but has a value, validate the regex
    if (value && field.validation?.regex && !field.validation.regex.test(value)) {
      return field.validation.errorMessage;
    }

    if (field.validation?.custom && !field.validation.custom(value)) {
      return field.validation.errorMessage;
    }

    // No validation errors
    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Validate each field and store errors in validationErrors
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) validationErrors[field] = error;
    });


    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSubmitStatus({
        show: true,
        message: 'Please fix the errors and try again.',
        type: 'danger'
      });
      return;
    }

    setLoading(true);
    setPageLoading(true);

    // Create a FormData object for multipart/form-data
    // const formDataToSend = new FormData();
    // Object.keys(formData).forEach((key) => {
    //   formDataToSend.append(key, formData[key]);
    // });
    const jsonPayload = { ...formData };
    // Ensure service.EditProfile sends data as multipart
    console.log("jsonPayload ===> ", jsonPayload);

    service.updateUser(jsonPayload)
      .then(async (response) => {
        if (response.status === 200) {
          setLoading(false);
          setPageLoading(false);
          Swal.fire({
            title: "Success",
            text: t("your_profile_update_successfully"),
            icon: "success",
            showConfirmButton: false,
            timer: 10000,
          }).then(() => {
            history.push("/");
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setPageLoading(false);
        console.log("ERROR ====>", err);

        if (err.response && err.response.data) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            Swal.fire(t("warning"), data.message || data, "warning")
          } else {
            Swal.fire(t("error"), t("unexpected_error_message"), "error")
          }
        } else {
          Swal.fire(t("error"), t("network_error"), "error")
        }
      });
  };

  const groupedFields = formFields.reduce((groups, field) => {
    const { row = 1 } = field;
    if (!groups[row]) groups[row] = [];
    groups[row].push(field);
    return groups;
  }, {});

  return (
    <div className="edit-profile-page">
      <HeaderTwo />
      <BreadcrumbBox title={t('edit_profile')} />
      {pageLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the transparency as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // Ensure it's above other elements
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
          </div>
        </div>
      )}

      <Container fluid className="edit-profile-area">
        <Card className="edit-profile-card">
          <Card.Header className="text-center border-0 bg-transparent pt-4">
            <div className="edit-profile-title">
              <h2>{t('edit_profile')}</h2>
            </div>
          </Card.Header>
          <Card.Body className="px-4 py-4">
            {submitStatus.show && (
              <Alert
                variant={submitStatus.type}
                onClose={() => setSubmitStatus({ ...submitStatus, show: false })}
                dismissible
                className="mb-4"
              >
                {submitStatus.message}
              </Alert>
            )}

            <Form>
              {Object.entries(groupedFields).map(([row, fields]) => (
                <Row key={row} className="mb-3">
                  {fields.map((field) => {
                    const isVisible = fieldStates[`${field.name}Visible`] !== false; // Default is true unless explicitly set to false
                    const isDisabled = fieldStates[`${field.name}Disabled`] === true;

                    return (
                      isVisible && (
                        <Col key={field.name} md={field.col || 6} sm={12}>
                          <Form.Group className="form-group">
                            <div className="d-flex align-items-center">
                              <Form.Label className="form-label mb-0">
                                {t(field.label)}
                                {field.required && <span className="text-danger">*</span>}
                              </Form.Label>
                              {field.infoTop && (
                                <div className="field-top-info ms-3">
                                  <p className="text-muted mb-0">{field.infoTop.message}</p>
                                  {field.infoTop.link && (
                                    <a
                                      href={field.infoTop.link.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="d-block text-primary mt-1"
                                    >
                                      {field.infoTop.link.text}
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                            {field.type === "file" ? (
                              <Form.Control
                                className={`form-media ${field.isDisabled ? 'disabled' : ''}`}
                                type="file"
                                name={field.name}
                                accept={field.accept.join(",")}
                                // onChange={handleFileChange}
                                isInvalid={!!errors[field.name]}
                                disabled={field.isDisabled}
                              />
                            ) : field.type === 'select' ? (
                              <Form.Select
                                className={`form-select ${field.isDisabled ? 'disabled' : ''}`}
                                name={field.name}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                                isInvalid={!!errors[field.name]}
                                disabled={field.isDisabled}
                              >
                                <option value="">{`Select ${field.label}`}</option>

                                {(() => {
                                  // Select the correct array of options based on the field name
                                  const options =
                                    field.name === 'qualId' ? allQualifications :
                                      field.name === 'userNationality' ? allNationalities :
                                        field.name === 'userCategory' ? allCategories :
                                          field.name === 'desgId' ? allDesignations :
                                            field.name === 'countryId' ? allCountries :
                                              field.name === 'stateId' ? allStates :
                                                field.name === 'serviceDistrictId' ? allServiceDistricts :
                                                  field.options; // Fallback for static options if available

                                  // Render the options with appropriate label and value fields
                                  return options.map((option, index) => (
                                    <option
                                      key={index}
                                      value={
                                        option.qualId ||
                                        option.userNationalityId ||
                                        option.userCategoryId ||
                                        option.desgId ||
                                        option.countryId ||
                                        option.stateId ||
                                        option.serviceDistrictId ||
                                        option.value ||
                                        option
                                      }
                                    >
                                      {option.qualification ||
                                        option.userNationalityName ||
                                        option.userCategoryName ||
                                        option.designation ||
                                        option.countryName ||
                                        option.stateName ||
                                        option.serviceDistrictName ||
                                        option.label ||
                                        option
                                      }
                                    </option>
                                  ));
                                })()}
                              </Form.Select>
                            ) : field.type === 'date' ? (
                              <Form.Control
                                className={`form-input ${field.isDisabled ? 'disabled' : ''}`}
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                value={
                                  field.type === 'date' && formData[field.name]
                                    ? /^\d{4}-\d{2}-\d{2}$/.test(formData[field.name])
                                      ? formData[field.name] // Use the value if it's already in YYYY-MM-DD format
                                      : new Date(formData[field.name].split('-').reverse().join('-'))
                                        .toISOString()
                                        .split('T')[0] // Convert if it's not in the correct format
                                    : ''
                                }
                                onChange={handleInputChange}
                                isInvalid={!!errors[field.name]}
                                disabled={field.isDisabled}
                                maxLength={field.maxLength}
                              />
                            ) : (
                              <Form.Control
                                className={`form-input ${field.isDisabled ? 'disabled' : ''}`}
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                                isInvalid={!!errors[field.name]}
                                disabled={field.isDisabled}
                                maxLength={field.maxLength}
                              />
                            )}
                            <Form.Control.Feedback type="invalid">
                              {errors[field.name]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    );
                  })}
                </Row>
              ))}

              <div className="form-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? t('loading') : t('update_profile')}
                </button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default EditProfile;
