import React, { useEffect, useState } from 'react';
import service from '../../services/service';
import Header from '../../components/Header';
import { Styles } from '../../pages/account/styles/account';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import { Button, Card, Form, Container, Row, Col, } from "react-bootstrap";
import RenderOnAuthenticated from '../../pages/account/RenderOnAuthenticated';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import UserService from '../../services/UserService';
import "./userProfile.css";

const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]
const UserProfile = (props) => {

    useEffect(() => {
        UserService.generateToken();
    }, []);

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])
    const history = useHistory();


    const [getUserDetails, setUserDetails] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        mobile: '',
        updateBy: props.match.params.updateBy,
        learnerUsername: props.match.params.learnerUsername,
        designation: '',
        serviceDistrict: '',
        firstNameError: '',
        lastNameError: '',
        mobileError: '',
        designationError: '',
    });

    let id = props.match.params.id;
    useEffect(() => {
        service.getUserById(id)
            .then(res => {
                setUserDetails(res.data);

            })
            .catch(err => {

            })
    }, [])




    const [getCountry, setCountry] = useState([]);
    // useEffect(() => {
    //     service.getCountry()
    //         .then(res => {
    //             ////console.log("Country Response ---- " + res.data);
    //             setCountry(res.data);
    //         })
    //         .catch(err => {

    //         })
    // }, [])

    // const [getStates, setStates] = useState([]);
    // useEffect(() => {
    //     service.getState()
    //         .then(res => {
    //             setStates(res.data);

    //         })
    //         .catch(err => {

    //         })
    // }, [])


    // const [getdistrict, setdistrict] = useState([]);
    // const getDistricts = (stateId) => {
    //     service.getDistrict(stateId)
    //         .then(res => {
    //             setdistrict(res.data);

    //         })
    //         .catch(err => {

    //         })
    // }

    const [qualification, setQualification] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [serviceDistrict, setServiceDistrict] = useState([]);

    useEffect(() => {

        service.RegisterQualification()
            .then((res) => {

                setQualification(res.data);
            })
            .catch((err) => {

            })
        service.RegisterDesignation()
            .then((res) => {

                setDesignation(res.data);
            })
            .catch((err) => {

            })
        service.RegisterServiceDistrict()
            .then((res) => {
                setServiceDistrict(res.data);
            })
            .catch((err) => {
            })

    }, [])

    const [getState, setState] = useState([]);
    const handleSelectChange = (event) => {
        setState({
            result: event.target.value
        })
        setUserDetails({ ...getUserDetails, stateId: event.target.value })
        ////console.log(getUserDetails);

        // getDistricts(event.target.value);
        setChangeAddress(true);
        getUserDetails.districtMaster.districtId = 0;
        getUserDetails.districtMaster.districtName = "Please Select District";
        getUserDetails.districtId = 0;
        getUserDetails.districtName = "Please Select District";
        ////console.log(getUserDetails);
    }

    const [getDistrict, setDistricts] = useState([]);
    const handleDistrict = (event) => {
        setDistricts({
            result: event.target.value
        })
        setUserDetails({ ...getUserDetails, districtId: event.target.value })
        setChangeAddress(true);
        ////console.log(getUserDetails);

    }

    const [getcountry, setcountry] = useState([]);
    const handle = (event) => {
        setcountry({
            result: event.target.value
        })
        setUserDetails({ ...getUserDetails, countryId: event.target.value });
        setChangeAddressCountry(true);
        getUserDetails.stateMaster.stateId = 0;
        getUserDetails.stateMaster.stateName = "Please Select State";
        getUserDetails.stateId = 0;
        getUserDetails.stateName = "Please Select State";
        getUserDetails.districtMaster.districtId = 0;
        getUserDetails.districtMaster.districtName = "Please Select District";
        getUserDetails.districtId = 0;
        getUserDetails.districtName = "Please Select District";
    }


    const validate = () => {
        let firstNameError1 = '';
        let lastNameError1 = '';
        let mobileError1 = '';

        // if (!getUserDetails.idproofNumber) {
        //     idproofNumberError1 = "ID Proof Number can't be blank";
        // }
        // if (!getUserDetails.idproofNumber.match(/^[A-Za-z0-9 ]{2,50}$/)) {
        //     ddcertificateError1 = "Minimum 2 and Maxmimum 50 Character Are Allowed";
        // }
        // if (!getUserDetails.title) {
        //     TitleError1 = "title can't be blank";
        // }

        if (!getUserDetails.firstName) {
            firstNameError1 = t('first_name_cant_be_blank');
        }
        if (!getUserDetails.firstName.match(/^[A-Za-z ]{2,50}$/)) {
            firstNameError1 = "Minimum 2 and Maxmimum 50 Alphabet Character Are Allowed";
        }
        if (!getUserDetails.lastName) {
            lastNameError1 = t('last_name_cant_be_blank');
        }
        if (!getUserDetails.lastName.match(/^[A-Za-z ]{2,50}$/)) {
            lastNameError1 = "Minimum 2 and Maxmimum 50 Alphabet Character Are Allowed";
        }
        // if (!getUserDetails.gender) {
        //     genderError1 = t('select_any_one');
        // }
        // if (!getUserDetails.dob) {
        //     dobError1 = t('select_your_date_of_birth');
        // } if (getUserDetails.dob) {
        //     const d = new Date(getUserDetails.dob);
        //     let year1 = d.getFullYear();
        //     if (year - year1 <= 21) {
        //         dobError1 = "Invalid age! Age should be greater than 21. "
        //     }
        // }
        if (!getUserDetails.mobile) {
            mobileError1 = t('enter_your_ten_digit_mobile_number');
        }

        function isMobile(mobile) {
            // return /^[6-9]\d{9}$/.test(mobile);
            return /^[0-9]{10}$/.test(mobile);
        }
        if (!isMobile(getUserDetails.mobile)) {
            mobileError1 = t('not_a_valid_mobile_number');
        }

        if (firstNameError1 ||
            lastNameError1 ||
            mobileError1) {
            setUserDetails({
                ...getUserDetails,
                firstNameError: firstNameError1,
                lastNameError: lastNameError1,
                mobileError: mobileError1,
            });


            return false;
        }

        return true;
    }

    const [changeAddress, setChangeAddress] = useState(false);
    const [changeAddressCountry, setChangeAddressCountry] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        ////console.log("inside submit function");
        const isValid = validate();
        ////console.log("isValid " + isValid);

        let desgId = " ";
        let serviceDistrictId = " ";
        let qualId = " ";

        if (getUserDetails.designation === " " || getUserDetails.designation === undefined) {
            desgId = getUserDetails.designationMaster.desgId;
        }
        else {
            desgId = getUserDetails.designation;
        }

        if (getUserDetails.serviceDistrictId === " " || getUserDetails.serviceDistrictId === undefined) {
            serviceDistrictId = getUserDetails.serviceDistrictMaster.serviceDistrictId;
        }
        else {
            serviceDistrictId = getUserDetails.serviceDistrictId;
        }


        if (isValid) {
            let update = {
                email: getUserDetails.email,
                firstName: getUserDetails.firstName,
                lastName: getUserDetails.lastName,
                mobile: parseInt(getUserDetails.mobile),
                desgId: desgId,
                serviceDistrictId: serviceDistrictId,
                updateBy: getUserDetails.learnerUsername,
                learnerUsername: getUserDetails.learnerUsername,
            }


            service.updateUser(update)
                .then(async response => {
                    if (response.status === 200) {
                        await swal(t('profile_update'), "", "success")
                        history.push('/');
                    } else {
                        swal(t('services_is_down_please_update_after_sometime'), "", "warning")
                    }
                }).catch(err => alert(t('services_is_down_please_update_after_sometime')));
        }
    }

    var date = new Date();
    var str = '';
    var timeNow = '';
    var year, month, day, hour, min, sec;
    year = date.getFullYear();
    month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    day = date.getDate();
    day = day < 10 ? '0' + day : day;
    hour = date.getHours();
    hour = hour < 10 ? '0' + hour : hour;
    min = date.getMinutes();
    min = min < 10 ? '0' + min : min;
    sec = date.getSeconds();
    sec = sec < 10 ? '0' + sec : sec;

    str += year + '-' + month + '-' + day;
    str += 'T' + hour + ':' + min;

    timeNow += year + '-' + month + '-' + day;
    // timeNow += 'T' + hour + ':' + min + ':' + sec;

    ////console.log(getUserDetails);

    return (

        <Styles>
            <div className="main-wrapper registration-page">
                <Header />
                <BreadcrumbBox title="Update Profile" />
                <Container>
                    <Row>
                        <Col md="1"></Col>
                        <Col md="10">
                            <RenderOnAuthenticated>
                                <Card className="profile-box">
                                    <div className="profile-box-title">
                                        <h3>User Profile</h3>
                                    </div>
                                    <Card.Body>
                                        <Form>
                                            <Row style={{ margin: "20px" }}>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group>
                                                        <label>{t('email')}</label>
                                                        <Form.Control name="email"
                                                            defaultValue={getUserDetails.email}
                                                            onChange={onchange}
                                                            disabled
                                                            placeholder="Email"
                                                            type="text"
                                                            style={{ backgroundColor: "lightgrey" }}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group>
                                                        <label>{t('mobile')}</label>
                                                        <Form.Control name="mobile"
                                                            defaultValue={getUserDetails.mobile}
                                                            onChange={onchange}
                                                            placeholder="Mobile"
                                                            type="text"
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row style={{ margin: "20px" }}>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group>
                                                        <label>{t('first_name')}</label>
                                                        <Form.Control name="firstName" value={getUserDetails.firstName} minLength={2} maxLength={50} onChange={e => setUserDetails({ ...getUserDetails, firstName: e.target.value })} placeholder="First Name" type="text" ></Form.Control>
                                                        <p style={{ fontSize: 12, color: "red" }}>
                                                            {getUserDetails.firstNameError}
                                                        </p>
                                                    </Form.Group>

                                                </Col>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group>
                                                        <label>{t('last_name')}</label>
                                                        <Form.Control name="lastName" value={getUserDetails.lastName} minLength={2} maxLength={50} onChange={e => setUserDetails({ ...getUserDetails, lastName: e.target.value })} placeholder="Last Name" type="text"    ></Form.Control>
                                                        <p style={{ fontSize: 12, color: "red" }}>
                                                            {getUserDetails.lastNameError}
                                                        </p>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row style={{ margin: "20px", marginTop: "60px" }}>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group >
                                                        <label>{t('designation')}</label>
                                                        <Form.Control
                                                            onChange={e => setUserDetails({ ...getUserDetails, designation: e.target.value })}
                                                            as="select" placeholder="Designation">

                                                            <option value={getUserDetails.designationMaster ? getUserDetails.designationMaster.desgId ? getUserDetails.designationMaster.desgId : null : null}>{getUserDetails.designationMaster ? getUserDetails.designationMaster.designation ? getUserDetails.designationMaster.designation : null : null}</option>

                                                            {designation.map((designation, index) => {
                                                                return (
                                                                    <option name="designation"
                                                                        onChange={e => setUserDetails({ ...getUserDetails, designation: e.target.value })}
                                                                        value={designation.desgId} >{designation.designation}</option>
                                                                );
                                                            })

                                                            }
                                                        </Form.Control>
                                                        <p style={{ fontSize: 12, color: "red" }}>
                                                            {getUserDetails.designationError}
                                                        </p>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pl-1" md="6">
                                                    <Form.Group >
                                                        <label>{t('service_district')}</label>
                                                        <Form.Control
                                                            onChange={e => setUserDetails({ ...getUserDetails, serviceDistrictId: e.target.value })}
                                                            as="select" placeholder="Service District/Establishment">

                                                            <option value={getUserDetails.serviceDistrictMaster ?
                                                                getUserDetails.serviceDistrictMaster.serviceDistrictId ?
                                                                    getUserDetails.serviceDistrictMaster.serviceDistrictId : null : null}
                                                            >
                                                                {getUserDetails.serviceDistrictMaster ?
                                                                    getUserDetails.serviceDistrictMaster.serviceDistrictName ?
                                                                        getUserDetails.serviceDistrictMaster.serviceDistrictName : null : null}
                                                            </option>

                                                            {serviceDistrict.map((district, index) => {
                                                                return (
                                                                    <option name="service_district"
                                                                        onChange={e => setUserDetails({ ...getUserDetails, serviceDistrictId: e.target.value })}
                                                                        value={district.serviceDistrictId} >{district.serviceDistrictName}</option>
                                                                );
                                                            })

                                                            }
                                                        </Form.Control>
                                                        <p style={{ fontSize: 12, color: "red" }}>
                                                            {getUserDetails.designationError}
                                                        </p>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Button className="btn-fill pull-right mt-3" onClick={handleSubmit} variant="info">{t('update_profile')}</Button>
                                            <div className="clearfix"></div>
                                        </Form>

                                    </Card.Body>
                                </Card>
                            </RenderOnAuthenticated>
                        </Col>
                        <Col md="1"></Col>
                    </Row>
                    <br></br>
                </Container>
                <br></br>
            </div>
        </Styles>

    );

}

export default UserProfile;