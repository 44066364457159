import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './styles/Register.css';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import { Circles, ThreeCircles } from 'react-loader-spinner';
import service from '../../services/service';
import useNonce from '../../helper/Nonce';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { formFields } from './formConfig.js';
import adminServices from '../../services/adminServices.js';

const Register = () => {
  // const fetchAndSetNonce = useNonce();
  const history = useHistory();

  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ show: false, message: '', type: '' });
  const [fieldStates, setFieldStates] = useState({
    otherCountryVisible: false
    // organizationNameDisabled: true,
    // organizationNameVisible: false, //// CONDITIONAL VISIBILITY OF FIELDS //// NOT IN USE NOW
  });

  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allNationalities, setAllNationalities] = useState([]);
  const [allQualifications, setAllQualifications] = useState([]);
  const [allDesignations, setAllDesignations] = useState([]);
  const [allServiceDistricts, setAllServiceDistricts] = useState([]);

  const [data, setData] = useState();
  const [captchaHash, setCaptchaHash] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const [captchaKey, setCaptchaKey] = useState();
  const [inputCaptcha, setInputCaptcha] = useState();


  useEffect(() => {
    getCaptcha();
    // getAllCountry();
    // getAllStates();
    // getAllCategories();
    // getAllNationalities();
    // getAllQualifications();
    getAllDesignations();
    getAllServiceDistricts();

  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors(prev => ({ ...prev, [name]: error }));

    // Iterate over form fields to check dependencies and update field states
    formFields.forEach((field) => {
      // Handle enabled dependencies
      if (field.enabledDependencies && field.enabledDependencies.field === name) {
        const shouldEnable = field.enabledDependencies.condition(value);
        setFieldStates((prev) => ({
          ...prev,
          [`${field.name}Disabled`]: !shouldEnable,
        }));
      }

      // Handle visibility dependencies
      if (field.visibilityDependencies && field.visibilityDependencies.field === name) {
        const shouldShow = field.visibilityDependencies.condition(value);
        setFieldStates((prev) => ({
          ...prev,
          [`${field.name}Visible`]: shouldShow,
        }));
      }

      // Handle requireDependencies
      if (field.requireDependencies && field.requireDependencies.field === name) {
        const shouldRequire = field.requireDependencies.condition(value);

        setFieldStates((prev) => ({
          ...prev,
          [`${field.name}Required`]: shouldRequire,
        }));

        // Validate the field if it becomes required
        if (shouldRequire) {
          const validationError = validateField(
            field.name,
            formData[field.name] || ''
          );
          setErrors((prev) => ({ ...prev, [field.name]: validationError }));
        } else {
          // Clear errors if the field is no longer required
          setErrors((prev) => ({ ...prev, [field.name]: null }));
        }
      }
    });
  };


  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    const field = formFields.find((field) => field.name === name);

    if (!file) {
      setErrors((prev) => ({ ...prev, [name]: "File is required." }));
      return;
    }

    // Validate file size
    if (file.size > field.maxSize) {
      setErrors((prev) => ({
        ...prev,
        [name]: `File size exceeds the limit of ${field.maxSize / (1024)} KB.`,
      }));
      return;
    }

    // Validate file type
    if (!field.accept.includes(file.type)) {
      setErrors((prev) => ({
        ...prev,
        [name]: `Invalid file type. Allowed types: ${field.accept.join(", ")}.`,
      }));
      return;
    }

    // Clear any previous errors
    setErrors((prev) => ({ ...prev, [name]: null }));

    // Update the form data
    setFormData((prev) => ({
      ...prev,
      [name]: file,
    }));
  };

  const validateField = (name, value) => {
    const field = formFields.find((f) => f?.name === name);
    if (!field) return null;

    // If the field is required, validate for non-empty value
    if ((fieldStates[`${name}Required`] || field.required)) {
      if (field.type === 'file' && (!value || value.length === 0)) {
        return `${field.label} is required`;
      }

      if (field.type === 'select' && (!value || value === '')) {
        return `${field.label} is required`;
      }

      if (typeof value === 'string' && value.trim() === '') {
        return `${field.label} is required`;
      }
    }

    // If the field is not required but has a value, validate the regex
    if (value && field.validation?.regex && !field.validation.regex.test(value)) {
      return field.validation.errorMessage;
    }

    if (field.validation?.custom && !field.validation.custom(value)) {
      return field.validation.errorMessage;
    }

    // No validation errors
    return null;
  };

  const getCaptcha = async () => {

    // fetchAndSetNonce("API_GATEWAY");

    setCaptchaLoading(true);
    service.getRegisterCaptcha()
      .then((res) => {
        console.log(res.data);
        setData(res.data.captchaImage);
        setCaptchaHash(res.data.captchaHash);
        setCaptchaToken(res.data.captchaToken);
        setCaptchaKey(res.data.captchaKey);
        setCaptchaLoading(false);
      }).catch((err) => {
        // console.log(err);
        setCaptchaLoading(false);
        // swal(t('error'), "Failed to load Captcha", "error");
      })
  }

  const getAllCountry = () => {
    setPageLoading(true);
    service.getCountry()
      .then((res) => {
        setAllCountries(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Country Data", "error");
      })
  }

  const getAllStates = () => {
    setPageLoading(true);
    service.getState()
      .then((res) => {
        setAllStates(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load States Data", "error");
      })
  }

  const getAllNationalities = () => {
    setPageLoading(true);
    service.getAllUserNationality()
      .then((res) => {
        setAllNationalities(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load States Data", "error");
      })
  }

  const getAllQualifications = () => {
    setPageLoading(true);
    service.RegisterQualification()
      .then((res) => {
        setAllQualifications(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Qualification Data", "error");
      })
  }

  const getAllDesignations = () => {
    setPageLoading(true);
    service.RegisterDesignation()
      .then((res) => {
        setAllDesignations(res.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Qualification Data", "error");
      })
  }

  const getAllServiceDistricts = () => {
    setPageLoading(true);
    service.RegisterServiceDistrict()
      .then((res) => {
        setAllServiceDistricts(res.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load Qualification Data", "error");
      })
  }

  const getAllCategories = () => {
    setPageLoading(true);
    service.getAllUserCategory()
      .then((res) => {
        setAllCategories(res?.data);
        setPageLoading(false);
      }).catch((err) => {
        // console.log(err);
        setPageLoading(false);
        // swal(t('error'), "Failed to load States Data", "error");
      })
  }

  const handleRefereshCaptcha = () => {
    setCaptchaLoading(true);
    service.getRegisterCaptcha()
      .then((res) => {
        console.log(res.data);
        setData(res.data.captchaImage);
        setCaptchaHash(res.data.captchaHash);
        setCaptchaToken(res.data.captchaToken);
        setCaptchaKey(res.data.captchaKey);
        setCaptchaLoading(false);
      }).catch((err) => {
        // console.log(err);
        setCaptchaLoading(false);
        // swal(t('error'), "Failed to load Captcha", "error");
      })
  }

  const autoRefereshCaptcha = () => {
    service.getRegisterCaptcha().then((res) => {
      setData(res.data.captchaImage);
      setCaptchaHash(res.data.captchaHash);
      setCaptchaToken(res.data.captchaToken);
      setCaptchaKey(res.data.captchaKey);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Validate each field and store errors in validationErrors
    formFields.forEach(({ name }) => {
      const error = validateField(name, formData[name] || '');
      if (error) validationErrors[name] = error;
    });

    // Validate captcha fields
    if (!inputCaptcha || inputCaptcha.trim() === '') {
      validationErrors.inputCaptcha = "Captcha is Required"; // Add a translation key for this
    }

    if (!captchaHash || !captchaToken || !captchaKey) {
      validationErrors.captcha = "Invalid Captcha"; // Add a translation key for this
    }

    // Perform additional email validation via API
    if (formData.email) {
      try {
        const response = await adminServices.checkEmailSpam(formData.email);
        if (response.status !== 200) {
          validationErrors.email = "This email is marked as spam. Please provide a different email.";
          return;
        }
      } catch {
        validationErrors.email = "Unable to validate email. Please try again later.";
        return;
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSubmitStatus({
        show: true,
        message: 'Please fix the errors and try again.',
        type: 'danger'
      });
      return;
    }

    setLoading(true);
    setPageLoading(true);

    // Create a FormData object for multipart/form-data
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    // Add captcha fields to formDataToSend
    formDataToSend.append("captchaInput", inputCaptcha.trim());
    formDataToSend.append("captchaHash", captchaHash);
    formDataToSend.append("captchaToken", captchaToken);
    formDataToSend.append("captchaKey", captchaKey);

    // Ensure service.Register sends data as multipart
    service.Register(formDataToSend)
      .then(async (response) => {
        if (response.status === 200) {
          setLoading(false);
          setPageLoading(false);
          Swal.fire({
            title: t("reg_success"),
            text: t("reg_success_desc"),
            icon: "success",
            showConfirmButton: false,
            timer: 10000,
          }).then(() => {
            history.push("/");
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERROR ====>", err);

        if (err.response && err.response.data) {
          const { status, data } = err.response;

          // Show error message from the server if available
          if (status >= 400 && status < 500) {
            swal(t("warning"), data.message || data, "warning")
              .then(() => {
                autoRefereshCaptcha();
              });
          } else {
            // Fallback for server errors (500 and above) or unexpected cases
            swal(
              t("error"),
              t("unexpected_error_message"), // Customize this to your translation key
              "error"
            ).then(() => {
              autoRefereshCaptcha();
            });
          }
        } else {
          // Fallback for network errors or unhandled cases
          swal(
            t("error"),
            t("network_error"), // Customize this to your translation key
            "error"
          ).then(() => {
            autoRefereshCaptcha();
          });
        }
      });
    setLoading(false);
  };

  const groupedFields = formFields.reduce((groups, field) => {
    const { row = 1 } = field;
    if (!groups[row]) groups[row] = [];
    groups[row].push(field);
    return groups;
  }, {});



  return (
    <div className="registration-page">

      <HeaderTwo />

      <BreadcrumbBox title={t('registration')} />
      {pageLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the transparency as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // Ensure it's above other elements
          }}
        >
          {/* Loader */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
          </div>
        </div>
      )}

      <Container fluid className="registration-area">
        <Card className="registration-card">
          <Card.Header className="text-center border-0 bg-transparent pt-4">
            <div className="registration-title">
              <h2>{t('registration')}</h2>
            </div>
          </Card.Header>
          <Card.Body className="px-4 py-4">
            {submitStatus.show && (
              <Alert
                variant={submitStatus.type}
                onClose={() => setSubmitStatus({ ...submitStatus, show: false })}
                dismissible
                className="mb-4"
              >
                {submitStatus.message}
              </Alert>
            )}

            <Form>
              {Object.entries(groupedFields).map(([row, fields]) => (
                <Row key={row} className="mb-3">
                  {fields.map((field) => {
                    const isVisible = fieldStates[`${field.name}Visible`] !== false; // Default is true unless explicitly set to false
                    const isDisabled = fieldStates[`${field.name}Disabled`] === true;

                    return (
                      isVisible && (
                        <Col key={field.name} md={field.col || 6} sm={12}>
                          <Form.Group className="form-group">
                            <div className="d-flex align-items-center">
                              <Form.Label className="form-label mb-0">
                                {t(field.label)}
                                {field.required && <span className="text-danger">*</span>}
                              </Form.Label>
                              {field.infoTop && (
                                <div className="field-top-info ms-3">
                                  <p className="text-muted mb-0">{field.infoTop.message}</p>
                                  {field.infoTop.link && (
                                    <a
                                      href={field.infoTop.link.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="d-block text-primary mt-1"
                                    >
                                      {field.infoTop.link.text}
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                            {field.type === "file" ? (
                              <Form.Control
                                className="form-media"
                                type="file"
                                name={field.name}
                                accept={field.accept.join(",")}
                                onChange={handleFileChange}
                                isInvalid={!!errors[field.name]}
                                disabled={field.isDisabled}
                              />
                            ) : field.type === 'select' ? (
                              <Form.Select
                                className="form-select"
                                name={field.name}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                                isInvalid={!!errors[field.name]}
                                disabled={field.isDisabled}
                              >
                                <option value="">{`Select ${field.label}`}</option>

                                {(() => {
                                  // Select the correct array of options based on the field name
                                  const options =
                                    field.name === 'qualId' ? allQualifications :
                                      field.name === 'userNationality' ? allNationalities :
                                        field.name === 'userCategory' ? allCategories :
                                          field.name === 'desgId' ? allDesignations :
                                            field.name === 'countryId' ? allCountries :
                                              field.name === 'stateId' ? allStates :
                                                field.name === 'serviceDistrictId' ? allServiceDistricts :
                                                  field.options; // Fallback for static options if available

                                  // Render the options with appropriate label and value fields
                                  return options.map((option, index) => (
                                    <option
                                      key={index}
                                      value={
                                        option.qualId ||
                                        option.userNationalityId ||
                                        option.userCategoryId ||
                                        option.desgId ||
                                        option.countryId ||
                                        option.stateId ||
                                        option.serviceDistrictId ||
                                        option.value ||
                                        option
                                      }
                                    >
                                      {option.qualification ||
                                        option.userNationalityName ||
                                        option.userCategoryName ||
                                        option.designation ||
                                        option.countryName ||
                                        option.stateName ||
                                        option.serviceDistrictName ||
                                        option.label ||
                                        option
                                      }
                                    </option>
                                  ));
                                })()}
                              </Form.Select>
                            ) : (
                              <Form.Control
                                className="form-input"
                                type={field.type}
                                name={field.name}
                                placeholder={field.placeholder}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                                isInvalid={!!errors[field.name]}
                                maxLength={field.maxLength}
                                disabled={field.isDisabled}
                              />
                            )}
                            {/* Display Info (Message or Link) */}
                            {field.infoBottom && (
                              <div className="field-bottom-info mt-2">
                                <p className="text-muted mb-0">{field.infoBottom.message}</p>
                                {field.infoBottom.link && (
                                  <a
                                    href={field.infoBottom.link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="d-block text-primary mt-1"
                                  >
                                    {field.infoBottom.link.text}
                                  </a>
                                )}
                              </div>
                            )}
                            <Form.Control.Feedback type="invalid">
                              {errors[field.name]}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    );
                  })}
                </Row>
              ))}

              <Row className="captcha-container">
                <Col md="3">
                  {captchaLoading ? (
                    <ThreeCircles
                      height="40"
                      width="100"
                      color="#4fa94d"
                      visible={true}
                      ariaLabel="three-circles-rotating"
                    />
                  ) : (
                    <img src={`data:image/jpeg;base64,${data}`} className="captcha-image" alt="Captcha" />
                  )}
                </Col>
                <Col md="1">
                  <button
                    className="captcha-refresh-btn"
                    onClick={handleRefereshCaptcha}
                    type="button"
                  >
                    <i className="fa fa-refresh"></i>
                  </button>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <input
                      type="text"
                      name="inputCaptcha"
                      value={inputCaptcha}
                      placeholder={t('captcha')}
                      onChange={(e) => setInputCaptcha(e.target.value)}
                      className={`captcha-input ${errors.inputCaptcha ? 'error' : inputCaptcha ? 'success' : ''}`}
                      minLength="4"
                      maxLength="7"
                    />
                    {errors.inputCaptcha && <p className="captcha-input-msg">{errors.inputCaptcha}</p>}
                    {errors.captcha && <p className="captcha-error-msg">{errors.captcha}</p>}
                  </div>
                </Col>
              </Row>

              <button
                // type="submit"
                className="submit-button w-100 mt-4"
                disabled={loading}
                style={{
                  background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)',
                  color: 'white'
                }}
                onClick={handleSubmit}
              >
                {loading ? (
                  <span className="d-flex align-items-center justify-content-center">
                    <span className="spinner-border spinner-border-sm me-2" />
                    {t('loading')}
                  </span>
                ) : (
                  t('register_now')
                )}
              </button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Register;