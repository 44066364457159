import React, { useEffect, useState, useMemo } from 'react';
import StickyMenu from '../../components/common/StickyMenu';
import FilterDataTable from '../../pages/instructor/FilterDataTable';
import adminServices from '../../services/adminServices';
import learnerService from '../../services/learnerService';
import Footer from '../shared/Footer';
import Navbar from '../shared/Navbar';
import SettingsPanel from '../shared/SettingsPanel';
import Sidebar from '../shared/Sidebar';
import DataTable from "react-data-table-component";
import service, { URI, USER_API } from '../../services/service';
import swal from 'sweetalert';
import { Button, Modal } from 'react-bootstrap';
import UserService from '../../services/UserService';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import moment from 'moment';
import jsPDF from 'jspdf';
import { downloadExcel } from 'react-export-table-to-excel';
import axios from 'axios';
import { PreviewModal } from '../../pages/instructor/instCourses/CourseStructureDriveNew';

const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    //{
    //     code: 'hi',
    //     name: 'Hindi',
    //     country_code: 'in'
    // },
    // {
    //     code: 'te',
    //     name: 'Telugu',
    //     country_code: 'in'
    // },
    // {
    //     code: 'pu',
    //     name: 'Punjabi',
    //     country_code: 'in'
    // },
    // {
    //     code : 'mr',
    //     name : 'Marathi',
    //     country_code : 'in'
    // }
]

const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            paddingLeft: '0 8px',
            marginLeft: '10px'
        },
    },
};

function LearnerListToApprove() {

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])


    useEffect(() => {
        UserService.generateToken();
    }, []);

    const um_api = URI.SERVER?._USER_API;

    const [getLearnerData, setlearnerData] = useState([]);
    const [getLearnerDataStatus, setlearnerDataStatus] = useState(false);
    useEffect(() => {
        LearnersDatas();
    }, [getLearnerDataStatus])

    const LearnersDatas = async () => {
        try {
            let result = await adminServices.getAllRequestForLearner()
            setlearnerData(result.data);

            setlearnerDataStatus(false);
        } catch (e) {

            setlearnerDataStatus(false);
        }
    }
    const [approveLoading, setApproveLoading] = useState({
        isLoading: false
    })
    const [rejectLoading, setRejectLoading] = useState({
        isLoading: false
    })

    const [rowEmail, setRowEmail] = useState("")

    const onShowRejectModel = () => {
        setRejectCondition({
            show: true
        })
    }

    const [getApproveEmail, setApproveEmail] = useState("");
    const [getRejectEmail, setRejectEmail] = useState("");


    const ApproveAsLearner = async (email) => {

        setApproveEmail(email);
        setApproveLoading({ isLoading: true });
        // Previously there is an 'email' in Parameter
        // let data = { "rolename": "instructor", "username": email };
        const formData = new FormData();
        formData.append("email", email);
        service.assignLearnerRole(formData).then((resp) => {
            if (resp.data == "Success") {
                swal(`${t('success')}`, `${t('learner_approved')}`, "success");
                setApproveLoading({ isLoading: false });
                setlearnerDataStatus(true);
            }
        }).catch((e) => {
            swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")

            setApproveLoading({ isLoading: false });
        })

        // try {
        //     ////console.log(email);
        //     const formData = new FormData();
        //     formData.append("email", email);
        //     let result = await service.assignLearnerRole(formData);
        //     //Previously there is an 'data' in Parameter 
        //     // //console.log(learnerId);
        //     
        //     if (result.data == "Success") {
        //         await swal(`${t('success')}`, `${t('learner_approved')}`, "success");
        //         // InstructorRequestDatas();
        //         setApproveLoading({ isLoading: false });
        //         setlearnerDataStatus(true);
        //     }
        // } catch (e) {
        //     swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
        //     
        //     setApproveLoading({ isLoading: false });
        // }
    }



    const [rejectCondition, setRejectCondition] = useState({
        show: false
    })

    const onClickReject = async (email) => {
        setRejectEmail(email);
        setRejectLoading({ isLoading: true });
        onShowRejectModel();
        setRowEmail(email);
    }

    const columns = [
        // {
        //     name: "Learner",
        //     cell: (row) => <img src={um_api + `public/getprofilepic/${row.profilepicpath}`} style={{ width: '42px', height: '42px', borderRadius: '100%', boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)" }} />,
        //     sortable: true,
        //     width: "100px",
        // },
        // {
        //     name: "S.No",
        //     selector: (row, index) => index + 1,
        //     //width: '100px',
        //     sortable: true,
        // },
        {
            name: "Name",
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
            wrap: true,
            //width: '150px',
        },
        {
            name: "Email",
            selector: row => row.email,
            wrap: true,
            sortable: true,
        },
        // {
        //     name: "Mobile",
        //     selector: row => row.mobile,
        //     sortable: true,
        // },
        // {
        //     name: "Regiment/Belt Number",
        //     selector: row => row.beltno,
        //     wrap : true,
        //     sortable: true,
        // },
        // {
        //     name: "GPF/CPF Number",
        //     selector: row => row.gpfno,
        //     sortable: true,
        // },
        {
            name: "Documents",
            //selector: row => row.gpfno,
            sortable: true,
            cell: (row) => <div>
                <div className="d-inline-block">
                    <button
                        onClick={() => viewPhoto(row.profilepicpath, row.email)}
                        type="button" class="btn btn-info m-2" disabled={PhotoLoading.isLoading ? "true" : ""}>
                        {PhotoLoading.isLoading ? PhotoEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fas fa-image fa-2x"></i></>) : (<><i class="fas fa-image fa-2x"></i></>)}
                    </button>
                    <button
                        onClick={() => viewID(row.idproofpicpath, row.email)}
                        type="button" class="btn btn-info m-2" disabled={IDLoading.isLoading ? "true" : ""}>
                        {IDLoading.isLoading ? IDEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fas fa-id-card fa-2x"></i></>) : (<><i class="fas fa-id-card fa-2x"></i></>)}
                    </button>
                    {/* <button
                        onClick={() => viewCertificate(row.certificatepath, row.email)}
                        type="button" class="btn btn-info m-2" disabled={CertificateLoading.isLoading ? "true" : ""}>
                        {CertificateLoading.isLoading ? IDEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fa fa-certificate fa-2x"></i></>) : (<><i class="fa fa-certificate fa-2x"></i></>)}
                    </button> */}
                </div>

            </div>

        },
        {
            name: "Action",
            sortable: true,
            wrap: true,
            // width: '300px',
            cell: (row) => <div>
                <div className="d-inline-block">
                    <button onClick={() => shareUrlModalShow(
                        row.firstName,
                        row.lastName,
                        row.email,
                        row.mobile,
                        row.designation,
                        row.serviceDistrict
                        // row.gender,
                        // row.address,
                        // row.city,
                        // row.districtName,
                        // row.stateName,
                        // row.pincode,
                        // row.countryName,
                        // row.eduQualification,
                        // row.instituteName,
                        // row.dob,
                        // row.title,
                        // row.mobile1,
                        // row.idproofNumber,

                    )} style={{ marginRight: "6px" }}
                        type="button" class="btn btn-info m-2" disabled={infoLoading.isLoading ? "true" : ""}>
                        {infoLoading.isLoading ? ShareUrlModal.email === row.email ? (<>{t('loading')}</>) : (<><i class="fa fa-info-circle"></i>  {t('info')}</>) : (<><i class="fa fa-info-circle"></i>  {t('info')}</>)}
                    </button>
                    {/* in parameter of ApproveAsInstructor previously there is " row.email " */}
                    <button onClick={() => ApproveAsLearner(row.email)} type="button" class="btn m-2" style={{ background: "green", color: "white" }} disabled={approveLoading.isLoading ? "true" : ""}>
                        {approveLoading.isLoading ? getApproveEmail === row.email ? (<>{t('loading')}</>) : (<> <i class="fa fa-thumbs-up"></i>  {t('approve')}</>) : (<> <i class="fa fa-thumbs-up"></i>  {t('approve')}</>)}
                    </button>
                    {/* {//console.log(row.districtName)} */}

                    <button type="button" onClick={() => onClickReject(row.email)} class="btn btn-danger m-2" disabled={rejectLoading.isLoading ? "true" : ""}>
                        {rejectLoading.isLoading ? getRejectEmail === row.email ? (<>{t('loading')}</>) : (<><i class="fa fa-thumbs-down">  {t('reject')}</i></>) : (<><i class="fa fa-thumbs-down">  {t('reject')}</i></>)}
                    </button>
                </div>

            </div>
        }
    ];


    /* Table content Filter and Search */
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const filteredItems = getLearnerData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const [ShareUrlModal, setShareUrlModal] = useState({
        show: false
    });

    const [infoLoading, setInfoLoading] = useState({
        isLoading: false
    })


    const shareUrlModalHide = () => {
        setShareUrlModal({ show: false });
        setInfoLoading({ isLoading: false });
    }
    const shareUrlModalShow = (
        firstname,
        lastname,
        email,
        mobile,
        designation,
        serviceDistrict,
        // gender,
        // address,
        // city,
        // district,
        // state,
        // pincode,
        // country,
        // qualification,
        // instituteName,
        // dob,
        // title,
        // mobile1,
        // idproofNumber,
    ) => {
        setInfoLoading({ isLoading: true });
        setShareUrlModal({
            show: true,
            firstName: firstname,
            lastName: lastname,
            email: email,
            mobile: mobile,
            designation: designation,
            serviceDistrict: serviceDistrict
            // gender: gender,
            // address: address,
            // district: district,
            // state: state,
            // pincode: pincode,
            // country: country,
            // qualification: qualification,
            // instituteName: instituteName,
            // dob: dob,
            // city: city,
            // title: title,
            // mobile1: mobile1,
            // idproofNumber: idproofNumber,
        });
    }

    const [getViewID, setViewID] = useState({
        show: false
    });
    const [getViewCertificate, setViewCertificate] = useState({
        show: false
    });
    const [getViewPhoto, setViewPhoto] = useState({
        show: false
    });
    const [IDLoading, setIDLoading] = useState({
        isLoading: false
    })
    const [CertificateLoading, setCertificateLoading] = useState({
        isLoading: false
    })

    const [PhotoLoading, setPhotoLoading] = useState({
        isLoading: false
    })

    const [PhotoEmail, setPhotoEmail] = useState();
    const [IDEmail, setIDEmail] = useState();
    const [CertificateEmail, setCertificateEmail] = useState();


    const IDCardHide = () => {
        setViewID({ show: false });
        setIDLoading({ isLoading: false });
        //setInfoLoading({ isLoading: false });
    }
    const ProfilePicHide = () => {
        setViewPhoto({ show: false });
        setPhotoLoading({ isLoading: false });
        //setInfoLoading({ isLoading: false });
    }
    const viewPhoto = (profilepicpath, email) => {
        setPhotoEmail(email);
        setPhotoLoading({ isLoading: true });
        setViewPhoto({
            show: true,
            profilepicpath: profilepicpath,
        });
    }
    const viewID = (idproofpicpath, email) => {
        setIDEmail(email);
        setIDLoading({ isLoading: true });
        setViewID({
            show: true,
            idproofpicpath: idproofpicpath,
        });
    }

    const viewCertificate = (certificatepath, email) => {
        setCertificateEmail(email);
        setCertificateLoading({ isLoading: true });
        setViewCertificate({
            show: true,
            certificatepath: certificatepath,
        });
    }

    const CertificateHide = () => {
        setViewCertificate({ show: false });
        setCertificateLoading({ isLoading: false });
        //setInfoLoading({ isLoading: false });
    }



    const onHideRejectModel = () => {
        setRejectCondition({
            show: false
        })
        setRejectRemark("");

    }
    const onSetRejectRemark = (event) => {
        setRejectRemark(event.target.value)
    }

    const [rejectRemark, setRejectRemark] = useState("");

    const onSubmitRejectRemark = async (event) => {
        event.preventDefault();

        try {
            ////console.log(rowEmail);
            const formData = new FormData();
            formData.append("email", rowEmail);
            let result = await service.rejectLearnerRole(formData);
            //Previously there is an 'data' in Parameter 
            // //console.log(learnerId);

            if (result.data == "success") {
                await swal(`${t('success')}`, `${t('learner_rejected_successfully')}`, "success");
                // InstructorRequestDatas();
                setRejectLoading({ isLoading: false });
                setlearnerDataStatus(true);
                onHideRejectModel();
            }
        } catch (e) {
            swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "success");

            setRejectLoading({ isLoading: false });
            onHideRejectModel();
        }
    }


    const exportPDF = () => {
        const unit = "pt";
        const size = "A3";
        const orientation = "portrait"
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Learner List";
        const headers = [["Name", "Email", "Mobile", "Designation", "Service District/Establishment Name"]];

        var checkData = getLearnerData.map((data) => [
            `${data.firstName} ${data.lastName}`
            , `${data.email}`
            , `${data.mobile}`
            , `${data.designation}`
            , `${data.serviceDistrict}`
        ])
        let content = {
            startY: 50,
            head: headers,
            body: checkData
        };
        doc.text(title, 40, 40);
        doc.autoTable(content);
        doc.save("LearnerList.pdf")
    }

    const exportExcel = () => {

        var checkData = [];
        const header = ["Name", "Email", "Mobile", "Designation", "Service District/Establishment Name"]
        getLearnerData.map((data) => {
            const name = `${data.firstName} ${data.lastName}`
            const email = `${data.email}`
            const mobile = `${data.mobile}`
            const designation = `${data.designation}`
            const serviceDistrictName = `${data.serviceDistrict}`
            const instData = [name, email, mobile, designation, serviceDistrictName]
            checkData.push(instData);
        })
        downloadExcel({
            fileName: "Course",
            sheet: "Userlist",
            tablePayload: {
                header,
                body: checkData,
            },
        })

    }

    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div>
                            <div className="page-header">
                                <h3 className="page-title">
                                    {t('request_for_learners')}
                                </h3>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>{t('learners')}</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('view_learner')}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <br>
                                    </br>
                                    <div className="d-flex px-2">
                                        <Button onClick={exportExcel} style={{ marginRight: "10px" }}>{t('export_to_excel')}</Button>
                                        <Button onClick={exportPDF}>{t('export_to_pdf')}</Button>
                                    </div>
                                    <br></br>
                                    <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        defaultSortField="Name"
                                        defaultSortAsc={true}
                                        striped
                                        pagination
                                        highlightOnHover
                                        customStyles={customStyles}
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                    />
                                </div>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal centered show={ShareUrlModal.show} onHide={() => shareUrlModalHide()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                        {t('user_details')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <td>Name : </td>
                                <td>
                                    {ShareUrlModal.firstName} {ShareUrlModal.lastName}
                                </td>
                            </tr>
                            <tr>
                                <td>Email : </td>
                                <td>{ShareUrlModal.email}</td>
                            </tr>
                            <tr>
                                <td>Mobile : </td>
                                <td>{ShareUrlModal.mobile}</td>
                            </tr>
                            <tr>
                                <td>Designation : </td>
                                <td>{ShareUrlModal.designation}</td>
                            </tr>
                            <tr>
                                <td>Service District/Establishment: </td>
                                <td>{ShareUrlModal.serviceDistrict}</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "right" }}>

                    <Button variant="secondary" onClick={() => shareUrlModalHide()}>
                        {t('cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* 
            <Modal centered show={getViewID.show} onHide={() => IDCardHide()} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                        {t('id_card')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ textAlign: "center" }}>
                        {getViewID.idproofpicpath && (
                            <img
                                className="img-fluid rounded"
                                src={um_api + `api/getidforadminverification/${getViewID.idproofpicpath}`}
                                style={{ maxWidth: '100%' }}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "right" }}>
                    <Button variant="secondary" onClick={() => IDCardHide()}>
                        {t('cancel')}
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <PreviewModal
                show={getViewID.show}
                onHide={() => IDCardHide()}
                contentType={"img"}
                url={um_api + `api/getidforadminverification/${getViewID.idproofpicpath}`}
                videoEncodeStatus={"false"}
            />
            {/* 
            <Modal centered show={getViewPhoto.show} onHide={() => ProfilePicHide()} backdrop="static" size="md">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                        {t('photo')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ textAlign: "center" }}>
                        {getViewPhoto.profilepicpath && (
                            <img
                                className="img-fluid rounded"
                                src={um_api + `api/getprofilepicforadminverification/${getViewPhoto.profilepicpath}`}
                                style={{ maxWidth: '100%' }}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "right" }}>
                    <Button variant="secondary" onClick={() => ProfilePicHide()}>
                        {t('cancel')}
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <PreviewModal
                show={getViewPhoto.show}
                onHide={() => ProfilePicHide()}
                contentType={"img"}
                url={um_api + `api/getprofilepicforadminverification/${getViewPhoto.profilepicpath}`}
                videoEncodeStatus={"false"}
            />

            {/* <Modal centered show={getViewCertificate.show} onHide={() => CertificateHide()} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                        {t('id_card')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ textAlign: "center" }}>
                        {getViewCertificate.certificatepath && (
                            <img
                                className="img-fluid rounded"
                                src={um_api + `api/getcertificateforadminverification/${getViewCertificate.certificatepath}`}
                                style={{ maxWidth: '100%' }}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "right" }}>
                    <Button variant="secondary" onClick={() => CertificateHide()}>
                        {t('cancel')}
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* <PreviewModal
                show={getViewCertificate.show}
                onHide={() => CertificateHide()}
                contentType={"img"}
                url={um_api + `api/getcertificateforadminverification/${getViewCertificate.certificatepath}`}
                videoEncodeStatus={"false"}
            /> */}

            <Modal centered show={rejectCondition.show} onHide={() => onHideRejectModel()} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
                        {t('why_u_want_to_reject_instructor_request')}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={onSubmitRejectRemark}>
                    <Modal.Body>

                        {/* <h6>REMARK</h6>
                        <br></br> */}
                        {/* <input type='text' class="form-control" aria-label="With textarea"></input> */}
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{t('remarks')}</span>
                            </div>
                            <textarea class="form-control" aria-label="With textarea" value={rejectRemark} onChange={onSetRejectRemark} ></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "right" }}>
                        <Button type='submit' variant="success">
                            {t('submit')}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>

    );
}

export default LearnerListToApprove;