export const formFields = [
    // Personal Information
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter your email',
        required: true,
        isDisabled: true,
        validation: {
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            errorMessage: 'Invalid email format',
        },
        maxLength: 30,
        row: 1,
        col: 6,
    },
    {
        name: 'mobile',
        label: 'Mobile Number 1',
        type: 'text',
        placeholder: 'Enter your mobile number',
        required: true,
        validation: {
            regex: /^[0-9]{10}$/,
            errorMessage: 'Invalid mobile number',
        },
        maxLength: 10,
        row: 1,
        col: 6,
    },
    {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
        placeholder: 'Enter your first name',
        required: true,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Only alphabets allowed',
        },
        maxLength: 50,
        row: 2,
        col: 6,
        className: 'custom-first-name',
    },
    {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        placeholder: 'Enter your last name',
        required: true,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Only alphabets allowed',
        },
        maxLength: 50,
        row: 2,
        col: 6,
    },

    {
        name: 'desgId',
        label: 'Designation',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 3,
        col: 6,
    },
    {
        name: 'serviceDistrictId',
        label: 'Service District Id',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 3,
        col: 6,
    }
];
