import React, { useEffect, useState, useMemo } from "react";
import StickyMenu from "../../components/common/StickyMenu";
import FilterDataTable from "../../pages/instructor/FilterDataTable";
import adminServices from "../../services/adminServices";
import learnerService from "../../services/learnerService";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import Sidebar from "../shared/Sidebar";
import DataTable from "react-data-table-component";
import service, { URI, USER_API } from "../../services/service";
import swal from "sweetalert";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UserService from "../../services/UserService";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import moment from "moment";
import { downloadExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import Swal from "sweetalert2";
import { PreviewModal } from "../../pages/instructor/instCourses/CourseStructureDriveNew";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  {
    code: "hi",
    name: "Hindi",
    country_code: "in",
  },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //   code: "pu",
  //   name: "Punjabi",
  //   country_code: "in",
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "17px",
      fontWeight: "500",
      paddingLeft: "0 8px",
      marginLeft: "10px",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
      // paddingLeft: '0 8px',
      // marginLeft: '10px'
    },
  },
};

function LearnersData() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    UserService.generateToken();
  }, []);

  const um_api = URI.SERVER?._USER_API;



  const [getLearnerData, setlearnerData] = useState([]);
  useEffect(() => {
    LearnersDatas();
  }, []);

  const LearnersDatas = () => {
    adminServices
      .getAllLearners()
      .then((resp) => {
        setlearnerData(resp.data);
      })
      .catch((err) => {

      });
  };

  // const LearnersDatas = async () => {
  //     try {
  //         let result = await adminServices.getAllLearners()
  //         
  //         setlearnerData(result.data);
  //     } catch (e) {
  //         
  //     }
  // }
  const [enableLearnerName, setenableLearnerName] = useState();
  const [disableLearnerName, setDisableLearnerName] = useState();

  const enableUser = (userId) => {
    setenableLearnerName(userId);

    setEnableLoading({ isLoading: true });

    adminServices
      .enableLearnerUser(userId)
      .then((resp) => {
        //console.log("Enable user == ",resp.status)
        if (resp.status === 200) {
          //console.log("Inside if Enable user == ",resp.status)

          swal(`${t("success")}`, `${t("user_enable_msg")}`, "success")
          LearnersDatas();
          setEnableLoading({ isLoading: false });
          //window.location.reload();


        }
      }

        // window.location.reload();


      )
      .catch((err) => {
        //console.log("inside the Catch enable")
        setEnableLoading({ isLoading: false });
        swal(
          `${t("error")}`,
          `${t("something_went_wrong_try_later")}`,
          "error"
        );

      });

    // setenableLearnerName(userId);

    // setEnableLoading({ isLoading: true });

    // adminServices.enableLearnerUser(userId).then((resp) => {
    //     swal(`${t('success')}`, `${t('user_enable_msg')}`, 'success').then(() => {
    //         //LearnersData();
    //         setEnableLoading({ isLoading: false });
    //         window.location.reload();
    //     })
    //     // window.location.reload();

    //    
    // }).catch((err) => {

    //     setEnableLoading({ isLoading: false });
    //    
    // })
  };

  const disableUser = (userId) => {
    setDisableLearnerName(userId);
    setDisableLoading({ isLoading: true });
    adminServices
      .disableLearnerUser(userId)
      .then((resp) => {
        //console.log("Enable user == ",resp.status)
        if (resp.status === 200) {
          //console.log("Inside if Disable user == ",resp.status)

          swal(t("success"), t("user_disable_msg"), "success");
          setDisableLoading({ isLoading: false });
          LearnersDatas();
          //window.location.reload();

        };
      }
        // window.location.reload();


      )
      .catch((err) => {
        //console.log("inside the Catch disable")

        setDisableLoading({ isLoading: false });
        swal(
          `${t("error")}`,
          `${t("something_went_wrong_try_later")}`,
          "error"
        );

      });

    // setDisableLearnerName(userId);
    // setDisableLoading({ isLoading: true });

    // adminServices
    //   .disableLearnerUser(userId)
    //   .then((resp) => {
    //     swal(`${t("success")}`, `${t("user_disable_msg")}`, "success").then(
    //       () => {
    //         setDisableLoading({ isLoading: false });
    //         //LearnersData();
    //         window.location.reload();
    //       }
    //     );
    //     // window.location.reload();

    //    
    //   })
    //   .catch((err) => {
    //     setDisableLoading({ isLoading: false });
    //    
    //   });
  };

  const sendAcitivationOnClick = (uid) => {

    Swal.fire({
      title: "Please Wait",
      text: "Mail is Sending",
      didOpen: () => {
        Swal.showLoading();
        adminServices.sendActivationLink(uid).then((resp) => {
          if (resp.data != "fail") {
            Swal.fire({
              icon: 'success',
              title: 'Mail Send Successfully',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              showConfirmButton: false,
              timer: 3000
            })
          }
        }).catch((err) => {

        })
      }
    })

  }

  const sendMailOnClick = (uid) => {

    Swal.fire({
      title: "Please Wait",
      text: "Mail is Sending",
      didOpen: () => {
        Swal.showLoading();
        adminServices.sendMailTempPasswordToLI(uid).then((resp) => {
          if (resp.data != "fail") {
            Swal.fire({
              icon: 'success',
              title: 'Mail Send Successfully',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              showConfirmButton: false,
              timer: 3000
            })
          }
        }).catch((err) => {

        })
      }
    })

  }
  const removeLearner = (learnerId) => {

    console.log("learnerId = ", learnerId)

    Swal.fire({
      title: "Please Wait",
      didOpen: () => {
        Swal.showLoading();
        adminServices.removeLearner(UserService.getUserid(), learnerId).then((resp) => {
          if (resp.data === "success") {
            Swal.fire({
              icon: "success",
              text: "Learner Delete Successfully",
              showConfirmButton: false,
              timer: 2000,
            })
            LearnersDatas();
          }
        }).catch((error) => {
          Swal.fire({
            icon: "error",
            text: "Something went Wrong Try Later",
            showConfirmButton: false,
            timer: 2000,
          })
        })
      }
    })
  }

  const columns = [
    // {
    //   name: "Learner",
    //   cell: (row) => (
    //     <img
    //       src={um_api + `public/getprofilepic/${row.learnerUsername}`}
    //       style={{
    //         width: "42px",
    //         height: "42px",
    //         borderRadius: "100%",
    //         boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)",
    //       }}
    //     />
    //   ),
    //   sortable: true,
    //   width: "100px",
    // },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Documents",
      //selector: row => row.gpfno,
      sortable: true,
      cell: (row) => <div>
        <div className="d-inline-block">
          <button
            onClick={() => viewPhoto(row.idproofpicpath, row.email, row.learnerUsername, row.profilepicpath)}
            type="button" class="btn btn-info m-2" disabled={PhotoLoading.isLoading ? "true" : ""}>
            {PhotoLoading.isLoading ? PhotoEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fas fa-image fa-2x"></i></>) : (<><i class="fas fa-image fa-2x"></i></>)}
          </button>
          <button
            onClick={() => viewID(row.idproofpicpath, row.email)}
            type="button" class="btn btn-info m-2" disabled={IDLoading.isLoading ? "true" : ""}>
            {IDLoading.isLoading ? IDEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fas fa-id-card fa-2x"></i></>) : (<><i class="fas fa-id-card fa-2x"></i></>)}
          </button>
          {/* <button
            onClick={() => viewCertificate(row.certificatepath, row.email)}
            type="button" class="btn btn-info m-2" disabled={CertificateLoading.isLoading ? "true" : ""}>
            {CertificateLoading.isLoading ? IDEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fa fa-certificate fa-2x"></i></>) : (<><i class="fa fa-certificate fa-2x"></i></>)}
          </button> */}
        </div>
      </div>

    },
    {
      name: "Action",
      sortable: true,
      wrap: true,
      cell: (row) => (
        <div className="row">
          <div className="d-inline-block">
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("info")}</Tooltip>}>
              <button onClick={() => shareUrlModalShow(
                row.firstName,
                row.lastName,
                row.email,
                row.mobile,
                row.designation,
                row.serviceDistrictName
                // row.gender,
                // row.address,
                // row.city,
                // row.districtName,
                // row.stateName,
                // row.pincode,
                // row.countryName,
                // row.eduQualification,
                // row.instituteName,
                // row.dob
              )
              } type="button" class="btn btn-info m-2" disabled={infoLoading.isLoading ? "true" : ""}>
                {
                  infoLoading.isLoading ? (
                    ShareUrlModal.email === row.email ? (
                      <>{t("loading")}</>
                    ) : (
                      <>
                        <i class="fa fa-info-circle fa-1x"></i>
                      </>
                    )
                  ) : (
                    <>
                      <i class="fa fa-info-circle fa-1x"></i>
                    </>
                  )}
              </button>

            </OverlayTrigger>

            {
              row.status === "blocked" ? (
                <>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("enable")}</Tooltip>}>
                    <button
                      onClick={() => enableUser(row.learnerUsername)}
                      type="button"
                      class="btn btn-danger m-2"
                    >
                      {enableLoading.isLoading ? (
                        enableLearnerName === row.learnerUsername ? (
                          <> {t("loading")}</>
                        ) : (
                          <>
                            <i class="fa fa-lock-open fa-1x"></i>
                          </>
                        )
                      ) : (
                        <>
                          <i class="fa fa-lock-open fa-1x"></i>
                        </>
                      )}
                    </button>
                  </OverlayTrigger>
                </>
              ) : null
            }
            {
              row.status !== "blocked" ? (
                <>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("disable")}</Tooltip>}>
                    <button type="button" onClick={() => {
                      disableUser(row.learnerUsername);
                    }} class="btn btn-success m-2 "                  >
                      {disableLoading.isLoading ? (
                        disableLearnerName === row.learnerUsername ? (
                          <>{t("loading")}</>
                        ) : (
                          <>
                            <i class="fa fa-lock fa-1x"></i>
                          </>
                        )
                      ) : (
                        <>
                          <i class="fa fa-lock fa-1x"></i>
                        </>
                      )}
                    </button>
                  </OverlayTrigger>
                </>
              ) : null
            }
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Send Temporary Password</Tooltip>}>
              <button className='btn btn-primary m-2' onClick={() => {
                sendMailOnClick(row.learnerUsername);
              }}><i class="fa fa-brands fa-telegram fa-1x"></i></button>
            </OverlayTrigger>

            {
              row.activationToken !== "NA" ?
                <>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Send Activation Link</Tooltip>}>
                    <button className='btn btn-primary m-2' onClick={() => {
                      sendAcitivationOnClick(row.learnerUsername);
                    }}><i class="fa fa-link fa-1x"></i></button>
                  </OverlayTrigger>
                </>
                :
                <>
                </>
            }
            {
              row.status == "Registered" || row.status == "registered" ?
                <>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Remove</Tooltip>}>
                    <button className="btn btn-danger btn-sm" onClick={() => { removeLearner(row.email) }}><i class="fa fa-trash" aria-hidden="true"></i></button>
                  </OverlayTrigger>
                </>
                :
                <>
                </>
            }

          </div>
        </div>
      ),
    },
  ];

  /* Table content Filter and Search */
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = getLearnerData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [ShareUrlModal, setShareUrlModal] = useState({
    show: false,
  });

  const [infoLoading, setInfoLoading] = useState({
    isLoading: false,
  });
  const [enableLoading, setEnableLoading] = useState({
    isLoading: false,
  });
  const [disableLoading, setDisableLoading] = useState({
    isLoading: false,
  });

  const [getViewID, setViewID] = useState({
    show: false
  });
  const [getViewCertificate, setViewCertificate] = useState({
    show: false
  });
  const [getViewPhoto, setViewPhoto] = useState({
    show: false
  });
  const [IDLoading, setIDLoading] = useState({
    isLoading: false
  })
  const [CertificateLoading, setCertificateLoading] = useState({
    isLoading: false
  })

  const [PhotoLoading, setPhotoLoading] = useState({
    isLoading: false
  })

  const [PhotoEmail, setPhotoEmail] = useState();
  const [IDEmail, setIDEmail] = useState();
  const [CertificateEmail, setCertificateEmail] = useState();


  const IDCardHide = () => {
    setViewID({ show: false });
    setIDLoading({ isLoading: false });
    //setInfoLoading({ isLoading: false });
  }
  const ProfilePicHide = () => {
    setViewPhoto({ show: false });
    setPhotoLoading({ isLoading: false });
    //setInfoLoading({ isLoading: false });
  }
  const viewPhoto = (profilepicpath, email, learnername, imagepath) => {
    setPhotoEmail(email);
    setPhotoLoading({ isLoading: true });
    setViewPhoto({
      show: true,
      learnername: learnername,
      imagepath: imagepath,
      profilepicpath: profilepicpath,
    });
  }
  const viewID = (idproofpicpath, email) => {
    setIDEmail(email);
    setIDLoading({ isLoading: true });
    setViewID({
      show: true,
      idproofpicpath: idproofpicpath,
    });
  }

  const viewCertificate = (certificatepath, email) => {
    setCertificateEmail(email);
    setCertificateLoading({ isLoading: true });
    setViewCertificate({
      show: true,
      certificatepath: certificatepath,
    });
  }

  const CertificateHide = () => {
    setViewCertificate({ show: false });
    setCertificateLoading({ isLoading: false });
    //setInfoLoading({ isLoading: false });
  }

  const shareUrlModalHide = () => {
    setShareUrlModal({ show: false });
    setInfoLoading({ isLoading: false });
  };
  const shareUrlModalShow = (
    firstname,
    lastname,
    email,
    mobile,
    designation,
    serviceDistrictName
    // gender,
    // address,
    // city,
    // district,
    // state,
    // pincode,
    // country,
    // qualification,
    // institute,
    // dob
  ) => {
    setInfoLoading({ isLoading: true });
    setShareUrlModal({
      show: true,
      firstName: firstname,
      lastName: lastname,
      email: email,
      mobile: mobile,
      designation: designation,
      serviceDistrictName: serviceDistrictName
      // gender: gender,
      // address: address,
      // district: district,
      // state: state,
      // pincode: pincode,
      // country: country,
      // qualification: qualification,
      // institute: institute,
      // dob: dob,
      // city: city,
    });
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Learner List";
    const headers = [["Name", "Email", "Mobile", "Designation", "Service District/Establishment Name"]];

    var checkData = getLearnerData.map((data) => [
      `${data.firstName} ${data.lastName}`
      , `${data.email}`
      , `${data.mobile}`
      , `${data.designation}`
      , `${data.serviceDistrictName}`
    ])
    let content = {
      startY: 50,
      head: headers,
      body: checkData
    };
    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save("LearnerList.pdf")
  }

  const exportExcel = () => {

    var checkData = [];
    const header = ["Name", "Email", "Mobile", "Designation", "Service District/Establishment Name"]
    getLearnerData.map((data) => {
      const name = `${data.firstName} ${data.lastName}`
      const email = `${data.email}`
      const mobile = `${data.mobile}`
      const designation = `${data.designation}`
      const serviceDistrictName = `${data.serviceDistrictName}`
      const instData = [name, email, mobile, designation, serviceDistrictName]
      checkData.push(instData);
    })
    downloadExcel({
      fileName: "LearnerList",
      sheet: "Userlist",
      tablePayload: {
        header,
        body: checkData,
      },
    })

  }

  return (
    <div className="container-scroller">
      <Navbar />
      <StickyMenu />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div>
              <div className="page-header">
                <h3 className="page-title">{t("learners")}</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="!#" onClick={(event) => event.preventDefault()}>
                        {t("learners")}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("view_learners")}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex px-2">
                    <Button onClick={exportExcel} style={{ marginRight: "10px" }}>{t('export_to_excel')}</Button>
                    <Button onClick={exportPDF}>{t('export_to_pdf')}</Button>
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortField="Name"
                    defaultSortAsc={true}
                    striped
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                    subHeader
                    subHeaderComponent={subHeaderComponent}
                  />
                </div>
              </div>
            </div>
            <SettingsPanel />
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        centered
        show={ShareUrlModal.show}
        onHide={() => shareUrlModalHide()}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ alignContent: "center" }}
          >
            {t("user_details")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td>Name : </td>
                <td>
                  {ShareUrlModal.firstName} {ShareUrlModal.lastName}
                </td>
              </tr>
              <tr>
                <td>Email : </td>
                <td>{ShareUrlModal.email}</td>
              </tr>
              <tr>
                <td>Mobile : </td>
                <td>{ShareUrlModal.mobile}</td>
              </tr>
              <tr>
                <td>Designation : </td>
                <td>{ShareUrlModal.designation}</td>
              </tr>
              <tr>
                <td>Service District/Establishment: </td>
                <td>{ShareUrlModal.serviceDistrictName}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button variant="secondary" onClick={() => shareUrlModalHide()}>
            {t("cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal centered show={getViewID.show} onHide={() => IDCardHide()} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
            {t('id_card')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ textAlign: "center" }}>
            {getViewID.idproofpicpath && (
              <img
                className="img-fluid rounded"
                src={um_api + `api/getidforadminverification/${getViewID.idproofpicpath}`}
                style={{ maxWidth: '100%' }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button variant="secondary" onClick={() => IDCardHide()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={getViewPhoto.show} onHide={() => ProfilePicHide()} backdrop="static" size="md">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
            {t('photo')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ textAlign: "center" }}>
            {getViewPhoto.imagepath ? (
              <img
                className="img-fluid rounded"
                src={um_api + `public/getprofilepic/${getViewPhoto.learnername}`}
                style={{ maxWidth: '100%' }}
              />
            ) : (<img
              className="img-fluid rounded"
              src={um_api + `api/getprofilepicforadminverification/${getViewPhoto.profilepicpath}`}
              style={{ maxWidth: '100%' }}
            />)}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button variant="secondary" onClick={() => ProfilePicHide()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal> */}

      <PreviewModal
        show={getViewPhoto.show}
        onHide={() => ProfilePicHide()}
        contentType={"img"}
        url={um_api + `api/getprofilepicforadminverification/${getViewPhoto.profilepicpath}`}
        videoEncodeStatus={"false"}
      />

      <PreviewModal
        show={getViewID.show}
        onHide={() => IDCardHide()}
        contentType={"img"}
        url={um_api + `api/getidforadminverification/${getViewID.idproofpicpath}`}
        videoEncodeStatus={"false"}
      />

      {/* <Modal centered show={getViewCertificate.show} onHide={() => CertificateHide()} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
            {t('id_card')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ textAlign: "center" }}>
            {getViewCertificate.certificatepath && (
              <img
                className="img-fluid rounded"
                src={um_api + `api/getcertificateforadminverification/${getViewCertificate.certificatepath}`}
                style={{ maxWidth: '100%' }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button variant="secondary" onClick={() => CertificateHide()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default LearnersData;
