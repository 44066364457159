export const formFields = [
    // Personal Information
    {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
        placeholder: 'Enter your first name',
        required: true,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Only alphabets allowed',
        },
        maxLength: 50,
        row: 1,
        col: 6,
        className: 'custom-first-name',
    },
    {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        placeholder: 'Enter your last name',
        required: true,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Only alphabets allowed',
        },
        maxLength: 50,
        row: 1,
        col: 6,
    },
    {
        name: 'mobile',
        label: 'Mobile Number 1',
        type: 'text',
        placeholder: 'Enter your mobile number',
        required: true,
        validation: {
            regex: /^[0-9]{10}$/,
            errorMessage: 'Invalid mobile number',
        },
        maxLength: 10,
        row: 2,
        col: 6,
    },
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter your email',
        required: true,
        validation: {
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            errorMessage: 'Invalid email format',
        },
        maxLength: 30,
        row: 2,
        col: 6,
    },
    {
        name: "profilephoto",
        label: "Upload User Photograph",
        type: "file",
        accept: ["image/png", "image/jpeg", "image/jpg", "pdf"], // Allowed file types
        maxSize: 200 * 1024, // Maximum file size in bytes (5MB here)
        required: true,
        infoBottom: {
            message: "Media file should be less than 200KB",
        },
        row: 3,
        col: 6,
    },
    {
        name: "identityPhoto",
        label: "Upload ID Proof",
        type: "file",
        accept: ["image/png", "image/jpeg", "image/jpg", "pdf"], // Allowed file types
        maxSize: 200 * 1024, // Maximum file size in bytes (5MB here)
        required: true,
        infoBottom: {
            message: "Media file should be less than 200KB",
        },
        row: 3,
        col: 6,
    },
    ,
    {
        name: 'desgId',
        label: 'Designation',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 4,
        col: 6,
    },
    {
        name: 'serviceDistrictId',
        label: 'Service District',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 4,
        col: 6,
    }
];
